<template>
  <div>
    <b-form-input list="my-list-id" @change="setSize($event)"></b-form-input >

    <datalist id="my-list-id" @change="setSize($event)">
      <option>Manual Option</option>
      <option @click="setSize(size)" v-for="size in sizes"  :key="size">{{ size }}</option>
    </datalist>
    {{ this.size }}
  </div>
</template>

<script>
  export default {
    data() {
      return {
        size: '',
        sizes: ['Small', 'Medium', 'Large', 'Extra Large']
      }
    },
    methods: {
      setSize(e) {
        if(!this.sizes.includes(e)) return 
        this.size = e
        console.log('====================================');
        console.log(e, this.size);
        console.log('====================================');
      }
    }
  }
</script>