<template>
  <div class="mainPage">


    <p id="headPage">Tambah Jurnal</p>
    <div class="filterRow">
      <div id="titleRow"></div>
      <form action="">

        <div class="inRow">
          <div class="inCol" id="cardFilter">
            <p id="inputTitle">Tanggal</p>
            <b-input type="date" v-model="date"></b-input>
          </div>
          <div class="inCol" id="cardFilter">
            <p id="inputTitle">Keterangan</p>
            <b-input type="text" v-model="notes" ></b-input>
          </div>
        </div>

        <!-- <p id="headPage">Sub Jurnal</p> -->
        <div class="tableRow">
          <div class="inRow">
            <lord-icon
              v-if="this.isLoading"
              src="https://cdn.lordicon.com/dpinvufc.json"
              trigger="loop"
              style="width:300px;height:300px">
            </lord-icon>
          </div>
        </div>
        <div id="titleRow">
          <b-button id="addModal" @click.prevent="addRow()">Tambah Baris</b-button>
        </div>

        <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
          <thead>
            <tr>
              <th>Kelompok Mata Anggaran</th>
              <th>Mata Anggaran</th>
              <th>Posisi</th>
              <th>Jumlah</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="e in subJurnals" :key="e.id">
              <td>
                <b-select v-model="e.KelompokMaId" required>
                  <option :value="x.id" v-for="x in kelMa" :key="x.id">{{x.name}}</option>
                </b-select>
              </td>
              <td>
                <b-select v-model="e.MataAnggaranId" required>
                  <option :value="x.id" v-for="x in getMa(e.KelompokMaId)" :key="x.id">{{x.name}}</option>
                </b-select>
              </td>
              <td>
                <b-select required v-model="e.NormalBalanceId">
                  <option value="1">Debet</option>
                  <option value="2">Kredit</option>
                </b-select>
              </td>
              <td>
                <b-input v-model.lazy="e.amount" type="text"  @input="formatAmount(e)" class="text-right" required></b-input>
              </td>
              <td>
                <button @click.prevent="deleteRow(e.id)">
                  <img src="../assets/trash.png" alt="">
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <b-button style="margin-top: 10px;" @click.prevent="addData()">Submit</b-button>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
import { v4 as uuidv4 } from 'uuid';

Vue.filter('currency', function (value) {
  if (!value) return ''
  let amount = value.toString().replace(/\D/g, '')
  return Number(amount).toLocaleString('id-ID')
});

export default {
  name: "Voucer",
  data() {
    return  {
      isLoading : false,
      id: 0,
      projects: [],
      types: [],
      isLoading: false,
      isEdit: false,

      subJurnals: [{
        id: 1,
        KelompokMaId: 0,
        MataAnggaranId: 0,
        SubMataAnggaranId: 0,
        NormalBalanceId : 0,
        amount: 0
      }],

      kelMa: [],
      ma: [],
      subMa: [],
      
      
      // filter
    
      // modal
      BranchId: 0,
      date:"",
      notes: "",
    }
  },
  created() {
    this.getMataAnggaran()
    this.date = moment(new Date).format("YYYY-MM-DD")
  },
  computed: {

  }, 
  methods: {

    
    addRow(){
      this.subJurnals.push(
        {
        id: uuidv4(),
        KelompokMaId: 0,
        MataAnggaranId: 0,
        NormalBalanceId : 0,
        amount: 0
      }
      )
    },
    formatAmount(subJurnal) {
      subJurnal.amount = subJurnal.amount.toString().replace(/\D/g, '')
      subJurnal.amount = Number(subJurnal.amount).toLocaleString('id-ID')
    },
    deleteRow(id) {
      this.subJurnals = this.subJurnals.filter(e => e.id !== id)
    },  
    async addData() {

      let subJurnals = this.subJurnals

      subJurnals = subJurnals.map(e => {
        return {
          ...e,
          amount: parseInt(e.amount.replace(/\./g, ""))
        }
      })

      let data = {
        subJurnals : subJurnals,
        date : this.date,
        notes : this.notes,
      }

      try {
        for await(const el of this.subJurnals) {

          if(el.ProjectId == 0) {
            throw {
              status: 500,
              msg: 'data is not complete'
            }
          }
        }

        let proses = await axios({
          method: "POST",
          url:"/jurnal",
          data: data,
          headers: {
            access_token: localStorage.getItem("access_token")
          },

        })
        Swal.fire('Berhasil', 'Data berhasil diproses', 'success');
        this.$router.push("/jurnal")
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      }
    },
    async getMataAnggaran() {
      this.isLoading = true
      try {
        let kelMa = await axios({
          method: "GET",
          url: "/database/kelma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })

        let ma = await axios({
          method: "GET",
          url: "/database/ma",
          params: {
            KelompokMaId: this.KelompokMaId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.kelMa = kelMa.data
        this.ma = ma.data
        console.log(this.kelMa,this.ma);
        this.isLoading = false

      } catch(err) {
        console.log(err);
      }
    },
    getMa(KelompokMaId) {
      // return (this.ma.find(e => KelompokMaId == e.KelompokMaId))
      return (this.ma.filter(el => el.KelompokMaId == KelompokMaId) || [])
    },


  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }
  button img{
    max-width: 24px;
  }




</style>