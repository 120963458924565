<template>
  <div class="mainPage">
    <p id="headPage">User</p>
    <div class="inRow filterRow">
      <div class="inCol" id="cardFilter">
        <p id="inputTitle">Keyword</p>
        <b-input type="text" v-model="keyword"  @change="this.getDataTable"></b-input>
      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()">Tambah Data</b-button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>ID</th>
            <th>Username</th>
            <th>Role</th>
            <th>Cabang</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td>{{ e.id }}</td>
            <td>{{ e.username }}</td>
            <td> {{e.role}} </td>
            <td id="action" class="text-center" >
                <button @click.prevent="showEdit(e)" title="Edit" >
                  <lord-icon
                    src="https://cdn.lordicon.com/nxaaasqe.json"
                    trigger="hover">
                  </lord-icon>
                </button>
                <button @click.prevent="confirmDelete(e.id)" title="Hapus">
                    <lord-icon
                        src="https://cdn.lordicon.com/gsqxdxog.json"
                        trigger="hover">
                    </lord-icon>
                </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
  <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Project</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">username</p>
              <b-input v-model="username" required type="text"></b-input>
            </div>
            <div class="inCol">
              <p id="titleModal">Password</p>
              <b-input v-model="password" required type="password"></b-input>
            </div>
            <div class="inCol">
              <p id="titleModal">Role</p>
              <b-select v-model="role" required>
                <option value="accountant">accountant</option>
                <option value="cashier">cashier</option>
                <!-- <option value="adminTeknik">Admin Teknik</option> -->

              </b-select>
            </div>
        
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>

<!-- Vehicle -->

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";

export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      dataTable: [],
      vehicle: [],
      currentPage: 1,
      perPage: 20,
			totalPage : 2,
      isLoading: false,
      isEdit: false,
      
      // filter
      findBranchId: 0,
      keyword: '',
    
      // modal
      BranchId: 0,
      username: "",
      password: "",
      role: "",
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
  },
  computed: {
    branches() {
      return this.$store.state.branches
    }
  },  
  methods: {

    showAdd() {
      this.isEdit = false
      this.BranchId = 0,
      this.projectName = ""
      $('#mainModal').modal('show')
    },
    showEdit(e) {
      this.isEdit = true
      this.id = e.id
      this.BranchId = e.BranchId
      this.projectName = e.projectName
      $('#mainModal').modal('show')
    },
    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/user",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            page: this.currentPage,
            limit: this.perPage,
            keyword: this.keyword

          }
        })
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    async addData() {

      try {
        
        let proses = await axios({
          method: "POST",
          url: "/user",
          data: {
            BranchId : this.BranchId,
            username : this.username,
            password : this.password,
            role : this.role,
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.BranchId = 0,
        this.projectName = "",
        $('#mainModal').modal('hide')
        Swal.fire('Berhasil', 'Data berhasil diproses', 'success');
      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
        console.log(err);
        this.isLoading = false
      }

    },


    async editData(e) {

      try {
        let data2 = await axios({
          method: "put",
          url: `/user/project/${this.id}`,
          data: {
            BranchId : this.BranchId,
            projectName : this.projectName,
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        $('#mainModal').modal('hide')
        this.getDataTable()
        this.username = 0,
        this.password = "",
        this.id=  0
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    }, 

    async deleteData(id) {
      
      try {
        
        let data =await  axios.delete(`/user/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.getDataTable()
      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>