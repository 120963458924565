<template>
  <div class="mainPage">
    <p id="headPage">Database Rumah</p>
    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">
          <div class="inCol" id="cardFilter">
            <p id="inputTitle">Keyword</p>
            <b-input type="text" v-model="keyword"  @change="this.getDataTable"></b-input>
        </div>
      </div>
    </div>
    <div id="titleRow">
        <b-button id="addModal" @click="showAdd" >Tambah</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>#</th>
            <th>Nomor</th>
            <th>Type</th>
            <th>Keterangan</th>
            <!-- <th colspan="2">Aksi</th> -->
            <th >Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(e,index) in this.dataTable" :key="e.id">
            <td>{{ index+1 }}</td>
            <td>{{ e.name }}</td>
            <td>{{ e.type }}</td>
            <td>{{ e.detail }}</td>
            <!-- <td class="uang"> {{ formatUang(e.amount)}} </td> -->
            <td id="action" class="text-center" >
                <!-- <button @click.prevent="showEdit(e)" title="Edit" > -->
                <button @click.prevent="getOneJurnal(e.id)" title="Edit" >
                  <img src="../assets/edit.png" alt="">
                </button>
                <button @click.prevent="confirmDelete(e.id)" title="Hapus"  >
                  <img src="../assets/trash.png" alt="">
                </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
    <b-modal ref="mainModal" id="mainModal" title="Database Rumah" @ok="addData">
      <form>
        <div class="inRow">
          <div class="inCol">
            <p id="titleModal">Nomor Rumah</p>
            <b-input v-model="name" type="text" required></b-input>
          </div>
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="titleModal">Type</p>
            <b-select v-model="type" required>
              <option v-for="(e,index) in this.types" :key="index" :value="e">{{ e }}</option>
            </b-select>
          </div>
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="titleModal">Keterangan</p>
            <b-input v-model="detail" type="text" required></b-input>
          </div>
        </div>
      </form>
      <!-- <b-button class="btn btn-secondary" @click="$refs.mainModal.hide()">Close</b-button> -->
    </b-modal>
    
  <!--  -->
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {
      jurnal: {},
      dataTable: [],
      currentPage: 1,
      keyword: '',
      perPage: 40,
			totalPage : 1,
      isLoading: false,
      isEdit: false,
      name: '',
      detail: '',
      type: '',

    }
  },
  created() {
    this.getDataTable()
  },
  computed: {
    types() {
      return this.$store.state.types
    },
    role() {
      return localStorage.getItem("role");
    },
  },  
  methods: {
    resetFilter() {
      this.endDate= ""
      this.startDate= ""
      this.keyword= ""
      this.getDataTable()
    },
    showAdd() {
      // return alert("Yess")
      this.$refs.mainModal.show();
      // $('#mainModal').modal('show')
    },
    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/home",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            page: this.currentPage,
            limit: this.perPage,
            keyword: this.keyword,
          }
        })
        this.dataTable = data
        this.totalPage = Math.ceil(data.length / this.perPage)
				// this.totalPage = data.length,
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    async addData() {
      this.isLoading = true
      try {
        let {data} = await axios({
          method: "post",
          url: "/home",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          data: {
            name: this.name,
            type: this.type,
            detail: this.detail,
          }
        })
        this.getDataTable()
        this.isLoading = true
      } catch(err) {
        console.log(err);
      }
    },
    cekStatus(data) {
      let result = ""
      if(data == 2) {
        result = 'By Planing'
      } else if(data == 1) {
        result= 'Urgent'
      }
      return result
    },
    formatUang(data) {
      // console.log(data);
			let uang = "";
			data = data.toString();
			for (let i = 0; i < data.length; i++) {
				if ((data.length - i) % 3 == 0 && i !== 0) {
				uang += `.${data[i]}`;
				} else {
				uang += data[i];
				}
			}
      return uang
    },

    async deleteData(id) {
      
      try {
        
        let data =await  axios.delete(`/jurnal/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.getDataTable()

      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }
  .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



</style>