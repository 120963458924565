import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    selectedJurnal: {},
    types: [
      '30/72',
      '32/75',
      '35/80',
      '38/84',
      '40/86',
      '42/88',
      '45/92'
    ], 
  },
  getters: {
  },
  mutations: {
    setIsLogin(state, data) {
      state.isLogin = data
    },
    setJurnal(state, data) {
      state.selectedJurnal = data
    },
  },
  actions: {
    getLocalStorage(context) {
      if(localStorage.getItem("access_token")) {
        context.commit('setIsLogin', true)
      }
    },
    getJurnal(context, data) {
      context.commit('setJurnal', data)
    }
  },
  modules: {
  }
})
