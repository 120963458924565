<template>
  <div class="mainPage">
    <div class="inCol">
      <img src="../assets/logo1.png" alt="">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',

}
</script>

<style scoped>
  img {
    max-width: 45rem;
  }

  .mainPage {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
