<template>
    <div class="LoginPage row m-0 p-0 justify-content-center align-items-center">
      <div class="LoginBox ">
          <div class="inCol">
            <div class="inRow">
            </div>
            <div class="inRow">
              <img src="../assets/logo1.png" alt="" id="logo">

            </div>
            <!-- <div class="inRow">
              <p id="title">Kurnia Residance</p>
            </div>
            <div class="inRow">
              <p id="subTitle">Masukkan username dan password.</p>
            </div> -->
            <form @submit.prevent="login()">
              <div>
                <p id="inputTitle">Username</p>
              </div>
              <div >
                <input type="text" v-model="username">
              </div>
              <div >
                <p id="inputTitle">Password</p>
              </div>
              <div >
                <input type="password" v-model="password">
              </div>
              <div >
                <b-button type="submit"> Login</b-button>
              </div>
            </form>
          </div>
        </div>
    </div>
    </template>
    
    <script>
    import axios from "../API/axios"
    import Swal from "sweetalert2";
    import moment from "moment";
    export default {
    name: "LoginPage",
    data() {
      return {
        username: "",
        password: ""
      }
    },
    computed: {
      isLogin() {
        return this.$store.state.isLogin
      }
    },
    methods: {
      async login() {
        try {
          let data = await axios({
            method: 'post',
            url: "/user/login",
            data: {
              username: this.username,
              password: this.password,
            },
          });
          let date = moment(new Date()).format("YYYY-MM-DD");
          // console.log(data, "data");
          this.$store.commit("setIsLogin", true);
          localStorage.setItem('access_token', data.data.access_token);
          localStorage.setItem('role', data.data.role);
          localStorage.setItem('username', data.data.username);
          localStorage.setItem('date', date);
          Swal.fire("Semangat Pagi", "Semangat Pagi, Semangat Bekerja", "success");
          // this.$store.dispatch('getLocalStorage');
          this.$router.push("/voucer");
        } catch (err) {
          console.log(err);
          Swal.fire("Maaf", "Password/username anda salah", "error");
        }
      },
    }
    }
    </script>
    
    <style scoped>
    * {
      margin: 0;
    }
    .LoginPage {
      display: flex;
    
      width: 100%;
      padding: 0;
      height: 100vh;
      background-color: #EDEDED;
    }
    .LoginBox{
      background-color: #FFFFFF;
      border-radius:20px;
      padding-right: 40px;
      padding-left: 40px;
      padding-top: 32px;
      padding-bottom: 32px;
      border: 1px solid rgb(163, 162, 162);
      width: 400px;
    
    }
    #logo{
      width: 8rem;
      margin-bottom: 10px;
    }
    
    #title{
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 4px;
    }
    
    
    #subTitle{
      font-size: 16px;
      font-weight: normal;
      color: #757575;
      margin-bottom: 60px;
    }
    
    #inputTitle{
      text-align: left;
      color: #9E9E9E;
      font-size: 18px;
    }
    input{
    width: 100%;
    border-radius: 20px;
    border: 1px #E0E0E0 solid;
    padding: 4px 10px;
    margin-bottom: 20px;
    margin-top: 4px;
    }
    
    button{
     width: 100%;
     border-radius: 20px;
      background-color: #020adb;
    }
    
    button:hover{
      color: #00803C;
      background-color: #FFFFFF;
    }
    
    input:active input:hover{
      border-color: #00803C;
    }
    
    </style>