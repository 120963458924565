import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginPage from "../views/LoginPage.vue"
import Voucer from "../views/Voucer.vue"
import Jurnal from "../views/Jurnal.vue"
import BukuBesar from "../views/BukuBesar.vue"
import AddJurnal from "../views/AddJurnal.vue"
import HomeDatabase from "../views/HomeDatabase.vue"
import User from "../views/User.vue"
import Neraca from "../views/Neraca.vue"

import Test from "../views/Test.vue"

import Swal from "sweetalert2";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
     meta: { requiresAuth: true }

  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { requiresAuth2: true }

  },
  {
    path: '/voucer',
    name: 'Voucer',
    component: Voucer,
     meta: { requiresAuth: true }

  },
  {
    path: '/homedatabase',
    name: 'HomeDatabase',
    component: HomeDatabase,
     meta: { requiresAuth: true }

  },
  {
    path: '/jurnal',
    name: 'Jurnal',
    component: Jurnal,
     meta: { requiresAuth: true }

  },
  {
    path: '/jurnal',
    name: 'BukuBesar',
    component: BukuBesar,
     meta: { requiresAuth: true }

  },
  {
    path: '/addjurnal',
    name: 'AddJurnal',
    component: AddJurnal,
     meta: { requiresAuth: true }
    
  },
  {
    path: '/neraca',
    name: 'Neraca',
    component: Neraca,
     meta: { requiresAuth: true }
    
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
    meta: { requiresAuth: true }

  },
  {
    path: '/user',
    name: 'User',
    component: User,
    meta: { requiresAuth: true }

  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => { // pengecekan meta required
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('access_token')) {
      Swal.fire("Maaf", "Anda Harus Login Terlebih dahulu", "error");
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

router.beforeEach((to, from, next) => { // pengecekan meta required
  if (to.matched.some(record => record.meta.requiresAuth2)) {
    if (localStorage.getItem('access_token')) {
      Swal.fire("Semangat Pagi", "Semangat Pagi dan Semangat Bekerja", "success");
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})


export default router
