<template>
  <div class="mainPage">
    <!-- {{ dataTable[0] }} -->
    <p id="headPage">Buku Besar</p>
    <form @submit.prevent="getDataTable()">

      <div class="filterRow">
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Kelompok Mata Anggaran</p>
          <b-select v-model="KelompokMaId" @change="this.getMa">
            <option value="0" disabled>Pilih Cabang </option>
            <option v-for="e in this.kelmas" :key="e.id" :value="e.id"> {{e.kelompokName}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Mata Anggaran </p>
          <b-select v-model="MataAnggaranId" @change="this.getSubMa">
            <option  v-for="e in this.mas" :key="e.id" :value="e.id"> {{e.mataAnggaranName}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Sub Mata Anggaran</p>
          <b-select v-model="SubMataAnggaranId" :filterable="true"  @change="this.getDataTable">
            <option v-for="e in this.submas" :key="e.id" :value="e.id"> {{e.subMataAnggaranName}} </option>
            <template slot="empty">Tidak ada hasil pencarian yang ditemukan.</template>
          </b-select>
        </div>
      </div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          
          <p id="inputTitle" >Tanggal Mulai</p>
          <b-input type="date"  v-model="startDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="endDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input v-model="keyword" @change="getDataTable" ></b-input>
        </div>
        
      </div>
    </div>
  </form>
  <div id="titleRow">
      <b-button id="addModal" type="submit">Cari</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
      <button @click.prevent="printCsv()" id="printer">
          <img src="../assets/printer.png" id="printer" alt="">
      </button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>No.Jurnal</th>
            <th>Proyek/Lokasi</th>
            <th>Sub Mata Anggaran</th>
            <th>Keterangan</th>
            <th>Posisi</th>
            <th>Jumlah</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td>{{ formatDate(e.Jurnal.date) }}</td>
            <td>{{ e.Jurnal.refNo}}</td>
            <td>{{ e.SubMataAnggaran ? e.SubMataAnggaran.subMataAnggaranName : e.SubMataAnggaranId }}</td>
            <td>{{ e.Jurnal.notes || "-" }}</td>
            <td>{{ e.NormalBalanceId == 1 ? 'Debet': 'Kredit' }}</td>
            <td id="uang" class="text-right"> {{ formatUang(e.amount) || 0}} </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      dataTable: [],
      projects: [],
      kelmas: [],
      mas: [],
      submas: [],
      currentPage: 1,
      perPage: 30,
			totalPage : 2,
      isLoading: false,
      isEdit: false,
      
      // filter

      KelompokMaId: 0,
      MataAnggaranId: 0,
      SubMataAnggaranId: 0,
      endDate: "",
      startDate: "",
      keyword: "",

    }
  },
  created() {
    this.getDataTable()
    this.getKelMa()
  },
  computed: {
  },  
  methods: {

    resetFilter() {

      this.endDate= ""
      this.startDate= ""
      this.keyword= ""
      this.getDataTable()
    },

    // data filter
    async getKelMa() {
      // alert("ok")
      try {
        let data = await axios({
          url: "/database/kelompokma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.kelmas = data.data
        
      } catch(err) {
        console.log(err)
      }
    },
    async getMa() {
      try {
        let {data} = await axios({
          url: "/database/mataanggaran",
          params: {
            KelompokMaId : this.KelompokMaId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.mas = data
      } catch(err) {
        console.log(err);
      }
    },
    async getSubMa() {
      try {
        let {data} = await axios({
          url: "/database/subma",
          params: {
            KelompokMaId : this.KelompokMaId,
            MataAnggaranId: this.MataAnggaranId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.submas = data
      } catch(err) {
        console.log(err);
      }
    },


    async printCsv() {
      this.isLoading = true
      try {
        
        // if(this.startDate == "" && this.endDate == "" ) {
        //   throw {
        //     status: 500,
        //     msg: "Data Tidak Lengkap"
        //   }
        // }
        let {data} = await axios({
          method: "GET",
          url: "/jurnal/subjurnal/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            keyword: this.keyword,
            BranchId: this.BranchId,
            ProjectId: this.ProjectId,
            KelompokMaId: this.KelompokMaId,
            MataAnggaranId: this.MataAnggaranId,
            SubMataAnggaranId: this.SubMataAnggaranId,
          }
        })
        console.log(data);
        const dataToConvert = {
          data: data,
          filename: 'Buku Besar',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
        console.log(err);
      }

    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    formatUang(data) {
      // console.log(data);
			let uang = "";
			data = data.toString();
			for (let i = 0; i < data.length; i++) {
				if ((data.length - i) % 3 == 0 && i !== 0) {
				uang += `.${data[i]}`;
				} else {
				uang += data[i];
				}
			}
      return uang
    },
    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "get",          
          url: "/jurnal/bukubesar",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            page: this.currentPage,
            limit: this.perPage,
            keyword: this.keyword,
            KelompokMaId: this.KelompokMaId,
            MataAnggaranId: this.MataAnggaranId,
            SubMataAnggaranId: this.SubMataAnggaranId,
          }
        })
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>